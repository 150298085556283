import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@mui/material/Button";
import { default as MuiLink } from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Link, graphql, useStaticQuery } from "gatsby";
import React from "react";
import Img from "gatsby-image";
import { faAmazon } from "@fortawesome/free-brands-svg-icons";

const Books = () => {
  const data = useStaticQuery(graphql`
    query BookImages {
      myUnfurling: file(relativePath: { eq: "My_Unfurling_cover.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      selfPublishing: file(
        relativePath: { eq: "self_publishing_book_cover.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div>
      <section className="p-14" id="latest-book">
        <div className="flex flex-wrap-reverse lg:flex-nowrap justify-center items-start gap-10">
          <div style={{ maxWidth: 450 }}>
            <div className="flex flex-col items-center gap-5">
              <div
                style={{
                  width: 215,
                  height: 332,
                  minWidth: 200,
                }}
              >
                <Link to="/self-publishing-book">
                  <Img
                    fluid={data.selfPublishing.childImageSharp.fluid}
                    alt="Yes, You Can Become an Author"
                  />
                </Link>
              </div>
              <div className="flex flex-col gap-4">
                <p className="text-left">
                  In{" "}
                  <em>
                    Yes, You Can Become an Author: Writing, Self-Publishing, and
                    Marketing Your First Book
                  </em>
                  , Lisa May Bennett shares tips from the journey behind her
                  debut book. She assembles a road map that covers the main
                  topics for self-publishing success.
                </p>
              </div>
              {/* <div>Purchase now: </div> */}
              <div className="flex flex-col flex-wrap gap-4 items-center justify-center">
                <Link to="/self-publishing-book">
                  <Button className="text-black">Learn More</Button>
                </Link>
                <MuiLink
                  target="_blank"
                  rel="noopener"
                  href="https://mybook.to/yesyoucan"
                  underline="none"
                  color="inherit"
                >
                  <Button
                    className="bg-yellow-400 text-black"
                    variant="contained"
                  >
                    Order Now
                  </Button>
                </MuiLink>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: 450 }}>
            <div className="flex flex-col items-center gap-5">
              <div
                style={{
                  width: 200,
                  minWidth: 200,
                }}
              >
                <Link to="/my-unfurling">
                  <Img
                    fluid={data.myUnfurling.childImageSharp.fluid}
                    alt="My Unfurling"
                  />
                </Link>
              </div>
              <div className="flex flex-col gap-4">
                <p className="text-left">
                  In{" "}
                  <em>
                    My Unfurling: Emerging from the Grip of Anxiety, Self-Doubt,
                    and Drinking
                  </em>
                  , Lisa May Bennett explores the childhood experiences that
                  paved the way for her decades-long drinking habit. In an
                  engaging and relatable voice, Lisa shares how sobriety helped
                  her cultivate self-esteem and inner peace.
                </p>
              </div>
              {/* <div>Purchase now: </div> */}
              <div className="flex flex-col flex-wrap gap-4 items-center justify-center">
                <Link to="/my-unfurling">
                  <Button className="text-black">Learn More</Button>
                </Link>
                <MuiLink
                  target="_blank"
                  rel="noopener"
                  href="https://mybook.to/myunfurling"
                  underline="none"
                  color="inherit"
                >
                  <Button
                    className="bg-yellow-400 text-black"
                    variant="contained"
                  >
                    Order Now
                  </Button>
                </MuiLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Books;
