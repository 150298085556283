import React from "react";
import About from "./About";
import Announcement from "./Announcement";
import Playlists from "./Playlists";
import Podcasts from "./Interviews";
import Reviews from "./Reviews";
import Subscribe from "./Subscribe";
import Books from "./Books";
import Interviews from "./Interviews";

const Body = () => {
  return (
    <main className="scroll-smooth">
      <ul>
        {/* <div className="pb-10">
          <Announcement />
        </div> */}
        <li>
          <Books />
        </li>
        <li className="bg-yellow-400">
          <Subscribe />
        </li>
        <li>
          <Interviews />
        </li>
        <li className="bg-yellow-400">
          <About />
        </li>
      </ul>
    </main>
  );
};

export default Body;
