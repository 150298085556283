import * as React from "react";
import Body from "../components/Body";
import Layout from "../components/Layout";

const Index = () => {
  return (
    <div>
      <Layout>
        <Body />
      </Layout>
    </div>
  );
};
export default Index;
